export interface HeatmapOptions {
  cellWidth: string;
  cellHeight: string;
  borderColor: string;
  colors: string[];
}

export const defaults: HeatmapOptions = {
  cellWidth: '80px',
  cellHeight: '14px',
  borderColor: '#A7F79A',
  colors: ['#FFFFFF', '#DAFFD5', '#C1FFB7', '#C1FFB7', '#65F44F', '#6DFC57', '#59E843', '#3AC924', '#3BCA25', '#159401', '#0F6601'],
};
