import React, { PureComponent } from 'react';
import { FormField } from '@grafana/ui';
import { PanelEditorProps } from '@grafana/ui';

import { HeatmapOptions } from './types';

export class WeekdayHeatmapEditor extends PureComponent<PanelEditorProps<HeatmapOptions>> {
  onHeightChanged = ({ target }: any) => {
    this.props.onOptionsChange({ ...this.props.options, cellHeight: target.value });
  };

  onWidthChanged = ({ target }: any) => {
    this.props.onOptionsChange({ ...this.props.options, cellWidth: target.value });
  };

  onBorderColorChanged = ({ target }: any) => {
    this.props.onOptionsChange({ ...this.props.options, borderColor: target.value });
  };

  onColorChanged = ({ target }: any) => {
    const index = parseInt(target.name, 10);

    if (index || index === 0) {
      const modifiedColors = [...this.props.options.colors];
      modifiedColors[index] = target.value;
      this.props.onOptionsChange({ ...this.props.options, colors: modifiedColors });
    }
  };

  render() {
    const { options } = this.props;

    return (
      <div>
        <div className="section gf-form-group">
          <h5 className="section-heading">Display</h5>
          <FormField
            label="Cell height"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onHeightChanged}
            value={options.cellHeight || ''}
          />
          <FormField label="Cell width" labelWidth={7} inputWidth={20} type="text" onChange={this.onWidthChanged} value={options.cellWidth || ''} />
          <FormField
            label="Border color"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onBorderColorChanged}
            value={options.borderColor || ''}
          />
        </div>
        <div className="section gf-form-group">
          <h5 className="section-heading">Colors</h5>
          <FormField label="0%" name="0" labelWidth={7} inputWidth={20} type="text" onChange={this.onColorChanged} value={options.colors[0] || ''} />
          <FormField
            label="10-20%"
            name="1"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[1] || ''}
          />
          <FormField
            label="20-30%"
            name="2"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[2] || ''}
          />
          <FormField
            label="30-40%"
            name="3"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[3] || ''}
          />
          <FormField
            label="40-50%"
            name="4"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[4] || ''}
          />
          <FormField
            label="50-60%"
            name="5"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[5] || ''}
          />
          <FormField
            label="60-70%"
            name="6"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[6] || ''}
          />
          <FormField
            label="70-80%"
            name="7"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[7] || ''}
          />
          <FormField
            label="80-90%"
            name="8"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[8] || ''}
          />
          <FormField
            label="90-99%"
            name="9"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[9] || ''}
          />
          <FormField
            label="100%"
            name="10"
            labelWidth={7}
            inputWidth={20}
            type="text"
            onChange={this.onColorChanged}
            value={options.colors[10] || ''}
          />
        </div>
      </div>
    );
  }
}
