import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/ui';
import { HeatmapOptions } from 'types';
import appEvents from 'grafana/app/core/app_events';
import { DataFrame } from '@grafana/data';
import ReactTooltip from 'react-tooltip';

interface Props extends PanelProps<HeatmapOptions> {}

export class WeekdayHeatmapPanel extends PureComponent<Props> {
  range(start: number, stop: number, step: number) {
    const a = [start];
    let b = start;
    while (b < stop) {
      a.push((b += step || 1));
    }
    return a;
  }

  weekDays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  getColor(numbers: number): string {
    if (numbers === 0) {
      return this.props.options.colors[0];
    }

    if (numbers === 100) {
      return this.props.options.colors[this.props.options.colors.length - 1];
    }

    const digits = Math.floor(Math.log10(numbers) + 1);
    if (digits === 1 || numbers === 10) {
      return this.props.options.colors[1];
    }

    const firstDigit = Math.floor(numbers / 10);
    return this.props.options.colors[firstDigit];
  }

  getTodayUTC(): Date {
    const now = new Date();
    return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
  }

  findEarliestDate(): Date {
    const today = this.getTodayUTC();

    const earliestDate = new Date(new Date().setDate(today.getDate() - (today.getDay() > 0 ? today.getDay() - 1 : 6)));
    earliestDate.setHours(0, 0, 0, 0);

    return earliestDate;
  }

  fillEmptyWorkdays(chartData: { [key: string]: { [key: number]: number } }) {
    const workDays = [...this.weekDays];
    const dayHours = this.range(0, 23, 1);

    for (const key in chartData) {
      workDays.splice(workDays.indexOf(key), 1);

      const hoursCopy = [...dayHours];
      for (const hour in chartData[key]) {
        hoursCopy.splice(hoursCopy.indexOf(parseInt(hour, 10)), 1);
      }

      const dateCopy = this.getTodayUTC();
      dateCopy.setMinutes(0, 0);

      for (const hour of hoursCopy) {
        chartData[key][hour] = 0;
      }
    }

    for (const workDay of workDays) {
      chartData[workDay] = {};
      for (const hour of dayHours) {
        chartData[workDay][hour] = 0;
      }
    }
  }

  getDateWithoutTime(date: Date): Date {
    const withoutTime = new Date(date);
    withoutTime.setHours(0, 0, 0, 0);

    return withoutTime;
  }

  buildWorkdayData(series: DataFrame[]): { [key: string]: { [key: number]: number } } {
    const chartData: { [key: string]: { [key: number]: number } } = {};

    for (const serie of series) {
      if (serie.fields.length !== 2) {
        appEvents.emit('alert-warning', ['', 'Query must return only time and a value.']);
      }

      const timeIndex = serie.fields.findIndex(f => f.name === 'Time');
      const valueIndex = !timeIndex ? 1 : 0;
      for (let i = 0; i < serie.fields[0].values.length; ++i) {
        const date = new Date(serie.fields[timeIndex].values.get(i));
        const val = serie.fields[valueIndex].values.get(i);

        const weekDay = this.weekDays[date.getDay()];
        let dayHours = chartData[weekDay];
        if (!dayHours) {
          chartData[weekDay] = {};
          dayHours = chartData[weekDay];
        }

        const dateMap = dayHours[date.getHours()];
        if (!dateMap) {
          dayHours[date.getHours()] = val;
        } else {
          dayHours[date.getHours()] += val;
        }
      }
    }

    this.fillEmptyWorkdays(chartData);

    return chartData;
  }

  render() {
    const { options, data, timeRange } = this.props;
    let days = timeRange.to.diff(timeRange.from, 'd');
    if (days == 0) days = 1;
    let i = 0;

    const chartData = this.buildWorkdayData(data.series);

    return (
      <div>
        <table style={{ margin: 'auto' }}>
          <tbody>
            {this.range(0, 23, 1).map(j => (
              <tr>
                {this.range(1, 7, 1).map(i => {
                  const idx = i === 7 ? 0 : i;
                  let utilization = (chartData[this.weekDays[idx]][j] * 100) / (days * data.series.length);
                  if (utilization > 0 && utilization < 1) {
                    utilization = 1;
                  }

                  return (
                    <td
                      style={{
                        width: options.cellWidth,
                        height: options.cellHeight,
                        backgroundColor: this.getColor(utilization),
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: options.borderColor,
                      }}
                      data-tip
                      data-for={`${j}+${i}`}
                    >
                      <ReactTooltip id={`${j}+${i}`}>
                        <span>{Math.floor(utilization) + '%'}</span>
                      </ReactTooltip>
                    </td>
                  );
                })}
                <td style={{ height: '14px', fontSize: '10px', width: '20px' }}>
                  <span style={{ marginLeft: '7px' }}>{i++}</span>
                </td>
              </tr>
            ))}

            <tr>
              <td style={{ width: '80px', textAlign: 'center' }}>MON</td>
              <td style={{ width: '80px', textAlign: 'center' }}>TUE</td>
              <td style={{ width: '80px', textAlign: 'center' }}>WED</td>
              <td style={{ width: '80px', textAlign: 'center' }}>THU</td>
              <td style={{ width: '80px', textAlign: 'center' }}>FRI</td>
              <td style={{ width: '80px', textAlign: 'center' }}>SAT</td>
              <td style={{ width: '80px', textAlign: 'center' }}>SUN</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
